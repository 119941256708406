<template>
  <div class="two-box">
    <div class="xueXi">学习中心</div>
    <div class="mycurriculum">
      <div class="fox">
        <div class="foxLeft" v-if="showBox">
          <div v-for="(item, index) in list" :key="index" class="foxLefta" @click="qiehuana(item)">
            <img class="img" :src="item.img">
            <span>{{ item.title }}</span>
            <div v-if="tabId == item.id" class="tips" />
          </div>
        </div>
        <!-- 我的课程 -->
        <div class="foxRight">
          <router-view />
        </div>
      </div>
      <div id="gaga1" class="live-haveCar">
        <timetable />
      </div>
    </div>
  </div>
</template>
<script>
import Bus from '@/api/bus'
import Vue from 'vue'
import Timetable from '@/views/mine/my/Timetable.vue'
export default {
  components: { Timetable },
  data() {
    return {
      userId: '',
      tabId: 1,
      list: [
        {
          id: 1,
          title: '我的课程',
          url: '/all/mycurriculum',
          icon: 'icon-wodekecheng',
          img: require('@/assets/img/learn/Group1.png')
        },
        {
          id: 4,
          title: '学习资料',
          url: '/all/mydata',
          icon: 'icon-a-zu320',
          img: require('@/assets/img/learn/Group2.png')
        },

        {
          id: 3,
          title: '我的题库',
          url: '/all/myexamination',
          icon: 'icon-a-zu319',
          img: require('@/assets/img/learn/Group3.png')
        },
        {
          id: 5,
          title: '我的考场',
          url: '/all/myRoom',
          icon: 'icon-a-zu321',
          img: require('@/assets/img/learn/Group4.png')
        },
        {
          id: 7,
          title: '我的作业',
          url: '/all/myOperation',
          icon: 'icon-a-zu321',
          img: require('@/assets/img/learn/Group7.png')
        },
        {
          id: 2,
          title: '最近学习',
          url: '/all/study',
          icon: 'icon-xuexijilu',
          img: require('@/assets/img/learn/Group5.png')
        },
        // {
        //   id: 6,
        //   title: '学习记录',
        //   url: '/all/record',
        //   icon: 'icon-xuexijilu',
        //   img: require('@/assets/img/learn/studyRecord.png')
        // }
      ]
    }
  },
  computed: {
    showBox() {
      if (this.$route.fullPath.includes('examinationRecord')) return false
      return true
    }
  },
  watch: {
    // 使用这个可以监听data中指定数据的变化,然后触发watch中对应的function的处理
    $route: function (newVal, oldVal) {
      if (oldVal.path == '/login' || newVal.path == '/login') {
        this.userId = localStorage.getItem('userId')
      }
    }
  },
  created() {
    this.userId = localStorage.getItem('userId')
    if (!this.userId) {
      Vue.prototype.goLoginView
      return
    }
    for (const item of this.list) {
      if (this.$route.path == item.url) {
        this.tabId = item.id
        break
      }
    } // 滚动条监控  答题卡 滚动到顶部悬浮
    $(document).scroll(function () {
      // 滚动到上面  隐藏
      /*       $(window).scroll(function () {
        var box = document.getElementById("gaga1");
        var rect = box.getBoundingClientRect();
        var clientTop = document.documentElement.clientTop;
        let top = $(window).scrollTop();
        var windowHeight = $(this).height(); //获取浏览器窗口高度
        var scrollHeight = $(document).height(); //获取文档高度
        let bottom = rect.bottom - clientTop;
        if (top >= 130 && bottom > 320) {
          $(".live-haveCar").addClass("fixed-card");
        } else {
          $(".live-haveCar").removeClass("fixed-card");
        }
      }); */
    })
  },
  methods: {
    // 我的课程
    qiehuana(item) {
      if (this.tabId == item.id) {
        return
      }
      this.tabId = item.id
      this.$router.replace(item.url)
    }
  }
}
</script>
<style lang="less" scoped>
.mycurriculum {
  width: 100%;
  background: #f5f7f9;
  display: flex;

  /*   justify-content: center; */
  .fox {
    width: 880px;
    margin-right: 20px;
    margin-left: calc(50% - 610px);
    min-height: 730px;

    .foxLeft {
      display: flex;
      width: 100%;
      height: 88px;
      border-bottom: 1px solid #eee;
      padding: 0px 24px;
      background: #ffffff;

      .foxLefta {
        padding: 32px 0px 24px;
        display: flex;
        align-items: center;
        cursor: pointer;
        height: 100%;
        margin-right: 22px;
        position: relative;

        &:last-child {
          margin-right: 0px;
        }

        display: flex;
        justify-content: flex-start;
        align-items: center;

        .img {
          width: 26px;
          height: 26px;
        }

        .iconfont {
          font-size: 16px;
        }

        span {
          font-size: 16px;
          font-family: Microsoft YaHei-Regular;
          font-weight: 400;
          color: #333333;
          line-height: 16px;
          margin-left: 8px;
        }

        .tips {
          position: absolute;
          bottom: 0px;
          width: 48px;
          height: 4px;
          background: #ff5e51;
          border-radius: 2px 2px 2px 2px;
          opacity: 1;
          left: calc(50% - 24px);
        }
      }

      .on {
        background: #fcefed;

        .foxLeftaL {
          background: #ff4027;
        }

        .foxLeftaR {
          span {
            color: #ff4027;
          }

          .iconfont {
            color: #ff4027;
          }
        }
      }
    }
  }

  /* 最近直播 */
  .live-haveCar {
    width: 320px;
    height: 560px;
    background: #ffffff;
    border-radius: 8px 8px 8px 8px;
    opacity: 1;
  }
}

.foxRight {
  margin-bottom: 20px;
}

.zhicheng {
  width: 180px;
  flex-shrink: 0;
  height: 100%;
}

.fixed-card {
  position: fixed;
  top: 0px;
  right: calc(50% - 610px);
}

.xueXi {
  background: url("~@/assets/img/homeSeventh/Group 2336.png");
  background-size: cover;
  color: #fff;
}</style>
